import * as React from "react"
import { graphql } from "gatsby"
import { css } from "linaria"

import Layout from "../components/layout"

import { blogWidth, fontFamily } from "../consts";

const cn = {
  notFound: css`
    ${fontFamily}
    max-width: ${blogWidth};
    text-align: center;
    font-size: 1.1rem;
    color: #fff;
    margin-top: 0.5rem;
    margin-left: auto;
    margin-right: auto;
  `,
  head: css`
    margin-bottom: 1rem;
    font-size: 1.5rem;
    text-transform: uppercase;
  `
}

const NotFoundPage = ({ data, location }) => {
  return (
    <Layout location={location} title={`Not found`}>
      <div className={cn.notFound}>
        <h1 className={cn.head}>404: Not Found</h1>
        <p>You just entered the level that doesn't exist 😢</p>
      </div>
    </Layout>
  )
}

export default NotFoundPage

export const pageQuery = graphql`
  query {
    site {
      siteMetadata {
        title
      }
    }
  }
`
